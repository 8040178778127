<!-- *************************** Wiki列表 ****************************** -->
<template>
  <div class="wiki">
    <h1 class="title">帮助文档</h1>
    <div class="wiki_info" v-if="isShowWikiInfo">
      <router-link to="/admin_center/wiki">
        <el-button class="back_list_btn" @click="backList">返回</el-button>
      </router-link>
      <div class="wiki_info_content" v-html="this.item.content"></div>
    </div>
    <div class="wiki_list" v-if="!isShowWikiInfo">
      <el-table :data="tableData" stripe border v-loading="loading">
        <el-table-column prop="type" label="类型" >
          <template slot-scope="scope">{{ type[scope.row.type] }}</template>
        </el-table-column>
        <el-table-column prop="title" label="标题" ></el-table-column>
        <el-table-column prop="updated_at" label="更新时间" ></el-table-column>
        <el-table-column prop="id" label="正文" >
          <template slot-scope="scope">
            <el-button @click="()=>getWikiInfo(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {getWikiInfoApi, getWikiListApi} from "api/Wiki";

export default {
  data() {
    return {
      loading: true,
      tableData: [
        {
          id: 0,
          title: "",
          type: 0,
          updated_at: ""
        }
      ],
      item: {
        id: 0,
        title: "",
        content: "",
        type: 0,
        updated_at: ""
      },
      type: {
        0: "未分类",
        1: "关于配置",
        2: "常见问题",
      },
      isShowWikiInfo: false
    };
  },
  mounted() {
    getWikiListApi().then(res => {
      if (res.code == 0) {
        this.loading = false;
        this.tableData = res.data;
      }
    });
  },
  methods: {
    getWikiInfo(id) {
      getWikiInfoApi({id}).then(res => {
        if (res.code === 0) {
          this.item = res.data
          this.isShowWikiInfo = true
        }
      });
    },
    backList() {
      this.isShowWikiInfo = false
      this.item = null
    }
  }
};
</script>
<style lang="scss" scoped>
.wiki {
  background: #fff;
  padding: 50px;

  .title {
    margin-bottom: 50px;
    font-size: 20px;
    color: #1a1a1a;
    text-align: left;
  }

  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: center;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  ::v-deep .el-table__header {
    width: 100% !important;
  }

  ::v-deep .el-table th {
    color: #fff;
    background: #4353b6;
  }

  .wiki_info {
    text-align: left;

    .wiki_info_content {
      margin-top: 50px;
      width: 90%;
    }
  }

}


</style>